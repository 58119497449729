@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import './variables.scss';

html,
body,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  font-family: $sw-font-family;
  color: $sw-foreground;
  outline: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 16px;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  display: none;
}

.chakra-toast__inner {
  max-width: 440px !important;
}

.chakra-alert * {
  color: unset;
  font-size: 18px;
}

.chakra-alert svg {
  color: unset;
  font-size: unset;
}

#chakra-toast-manager-bottom-right {
  bottom: 20px !important;
  right: 20px !important;
}

.chakra-modal__content-container {
  width: 100% !important;
  height: 100% !important;
}

.chakra-modal__body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.chakra-modal__content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 10px !important;
  max-height: 90vh !important;
  overflow: scroll;
}

.chakra-skeleton {
  border-radius: 5px !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReactModalPortal {
  z-index: 9999;
}

input::placeholder {
  color: #666666;
  opacity: 1;
}

input:focus::placeholder {
  color: #a0aec0;
}

svg.svgColored path { fill: currentColor; }